<template>
  <div id="detalle-garantia">
    <template v-if="solicitud">
    <div class="container">
      <h2 class="text-center d-block">Garantía</h2>
      <h5 class="text-center d-block">Solicitud: {{ solicitud.ServiciosOriginacion.id }}</h5>
      <h6 class="text-center d-block">Tipo Solicitud: {{ solicitud.tipo_solicitud }}</h6>
      
      <div>
        <ul class="nav nav-tabs nav-justified">
          
          <li class="nav-item" v-for="(garantia, index) of solicitud.garantias" :key="garantia.id" >
            <a class="nav-link" @click.prevent="setActive(index)" :class="{ active: isActive(index) }" href="#">Garantía-{{garantia.id}}</a>
          </li>
        </ul>
        <div class="tab-content py-3" id="myTabContent">
          <div class="tab-pane fade" :class="{ 'active show': isActive(index) }"  v-for="(garantia, index) of solicitud.garantias" :key="garantia.id" >
            <div class=' garantia col-md-12 row'>
              <div class="col-md-6">
                <!-- :src="'https://maps.googleapis.com/maps/api/streetview?size=500x400&location='+setImagenMap(garantia).latitud+','+setImagenMap(garantia).longitud+'&key=AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4'"  forma para mostrar las coordenadas de rasa o i360-->
                <!-- :src="'https://maps.googleapis.com/maps/api/staticmap?size=500x400&markers='+setImagenMap(garantia).latitud+','+setImagenMap(garantia).longitud+'&key=AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4&scale=1'"  forma para mostrar las coordenadas de rasa o i360 -->
                <img
                  :src="'https://maps.googleapis.com/maps/api/streetview?size=500x400&location='+garantia.latitud+','+garantia.longitud+'&heading='+garantia.heading+'&pitch='+garantia.pitch+'&key=AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4'" 
                  alt="" 
                  style="width:100%"
                  />
              </div> 
              <div class="col-md-6">
                <img
                  :src="'https://maps.googleapis.com/maps/api/staticmap?size=500x400&markers='+garantia.latitud+','+garantia.longitud+'&key=AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4&scale=1'" 
                  alt="" 
                  style="width:100%"
                  />
              </div> 
            </div>

            <imagenesGarantia v-if="garantia.fachada !== null" :imagenes="setImagenes(garantia.fachada.id)"></imagenesGarantia>

            <hr />
            <div class="garantia col-md-12 row">
              <div class="col-md-6">
                <div class=" content-tabla">
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Colateral
                    </span>
                    <span class="col text-right">
                      {{ formatRounded(garantia.colateral) }}
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Antiguedad en años
                    </span>
                    <span class="col text-right">
                      {{ garantia.edad }} 
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Calle
                    </span>
                    <span class="col text-right">
                      {{ garantia.calle }}
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Número exterior
                    </span>
                    <span class="col text-right">
                      {{ garantia.numero_ext}}
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Colonia
                    </span>
                    <span class="col text-right">
                      {{ garantia.colonia}}
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Municipio
                    </span>
                    <span class="col text-right">
                      {{ garantia.municipio }}
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Ciudad
                    </span>
                    <span class="col text-right">
                      {{ garantia.ciudad}}
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Estado
                    </span>
                    <span class="col text-right">
                      {{ garantia.estado}}
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Código Postal
                    </span>
                    <span class="col text-right">
                      {{ garantia.cp}}
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Situación
                    </span>
                    <span class="col text-right" v-if="solicitud.Scoring.avatar['existencia_gravamen'] != null">
                      {{ solicitud.Scoring.avatar['existencia_gravamen'] }}
                    </span>
                    <span class="col text-right" v-else-if="garantia.existencia_gravamen">
                      Tiene Gravamen
                    </span>
                    <span class="col text-right" v-else-if="garantia.existencia_gravamen == 0">
                      No tiene Gravamen
                    </span>
                    <span class="col text-right" v-else>
                      Sin Información
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Tipo Propiedad
                    </span>
                    <span class="col text-right">
                      {{ garantia.tipo_garantia.nombre }}
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Propietario
                    </span>
                    <span class="col text-right" v-if="solicitud.Scoring.avatar['dueno_colateral'] != null">
                      {{ solicitud.Scoring.avatar['dueno_colateral'] }}
                    </span>
                    <span class="col text-right" v-else-if="garantia.propia">
                      Es Propia
                    </span>
                    <span class="col text-right" v-else>
                      No es Propia
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Tipo Propiedad
                    </span>
                    <span class="col text-right">
                      {{ garantia.tipo_garantia.nombre }}
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Valor propiedad
                    </span>
                    <span class="col text-right">
                      ${{ formatPrice(setRasaOrI360(garantia).valor) }}
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Comercialización
                    </span>
                    <span class="col text-right">
                      {{ setRasaOrI360(garantia).tiempo_comercializacion }}
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Estado de conservación
                    </span>
                    <span class="col text-right">
                      {{ setRasaOrI360(garantia).estado_conservacion }}
                    </span>
                  </div>
                  <div class="generales-data">
                    <span class="heanding-mobile">
                      Evaluación (sobre 5)
                    </span>
                    <span class="col text-right">
                      {{ formatRounded(setRasaOrI360(garantia).score) }}
                    </span>
                  </div>
                </div>
              </div>
              <div :id="'map' + garantia.id" class="col-md-6" style="height: 500px"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div v-if="cesion_creditos">
      <VotacionCesionCredito/>
    </div>
    <div v-else>
      <Votacion />
    </div>
    </template>
  </div>
</template>

<script>
  import gmaps from '@/helpers/gmaps'
  import Votacion from '@/apps/comite/pages/Votacion'
  import imagenesGarantia from '@/apps/comite/pages/imagenes-garantia'
  import VotacionCesionCredito from '@/apps/comite/pages/VotacionCesionCreditos'
export default {
  name: 'garantia',
  components: {
    Votacion,
    imagenesGarantia,
    VotacionCesionCredito,
  },
  // created: async function() {
  //   this.solicitud = this.$store.state.solicitud;
  // },

  data: () => ({
    apiMap: null
    ,map: null
    ,marker: null
    ,street: null
    ,windowWidth: window.innerWidth
    ,solicitud: null
    ,activeItem: 0
    ,cesion_creditos: false
  }),

  async mounted() {
    this.solicitud = this.$store.state.solicitud;
    this.$log.info('solicitud: ',this.solicitud);

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });

    const google = await gmaps();
    this.$log.info('cargamos gmaps');

    for (const item in this.solicitud.garantias) {
      var latitud = '0.00000000';
      var longitud = '0.00000000';
      // if ((this.setRasaOrI360(this.solicitud.garantias[item]).latitud && this.setRasaOrI360(this.solicitud.garantias[item]).latitud != '0.00000000') 
      //  && (this.setRasaOrI360(this.solicitud.garantias[item]).longitud && this.setRasaOrI360(this.solicitud.garantias[item]).longitud != '0.00000000')) { para mostrar las de rasa o i360
      //     latitud  = this.setRasaOrI360(this.solicitud.garantias[item]).latitud;
      //     longitud = this.setRasaOrI360(this.solicitud.garantias[item]).longitud;
      // }else{
        latitud  =this.solicitud.garantias[item].latitud;
        longitud =this.solicitud.garantias[item].longitud;
      // }

      this.map = new google.maps.Map(document.getElementById('map'+this.solicitud.garantias[item].id), {
        center: {
          lat: parseFloat(latitud),
          lng: parseFloat(longitud)
        }
        ,zoom: 17
      })

      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(parseFloat(latitud), parseFloat(longitud)),
        map: this.map,
        title: 'Garantia'
      });

    }

    if(this.$auth.getUser().roles.comite_ver_creditos_cedidos){
      this.cesion_creditos = true
    }
  },

  methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(0).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatRounded(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "")
    },
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    setRasaOrI360(garantia){
      if (garantia.opinion_i360.length == 0 && garantia.opinion_rasa.length == 0)
        return {
          valor: 0
        };
      
      var rasa = garantia.opinion_i360[0];
      var i360 = garantia.opinion_rasa[0];
      if(rasa === undefined){
        return i360;
      }
      return rasa;
    },
    setImagenMap(garantia){
      var latitud = '0.00000000';
      var longitud = '0.00000000';
      if ((this.setRasaOrI360(garantia).latitud && this.setRasaOrI360(garantia).latitud != '0.00000000') 
       && (this.setRasaOrI360(garantia).longitud && this.setRasaOrI360(garantia).longitud != '0.00000000')) {
          latitud  = this.setRasaOrI360(garantia).latitud;
          longitud = this.setRasaOrI360(garantia).longitud;
      }else{
        latitud  =garantia.latitud;
        longitud =garantia.longitud;
      }

      var res = {
        'latitud': latitud,
        'longitud': longitud,
      }
      
      return res;
    },
    setImagenes(id_documento){
      const baseURL = process.env.VUE_APP_SERVICIOS_API;
      const api_token = process.env.VUE_APP_SERVICIOS_TOKEN

      return [
          baseURL+'documento/'+id_documento+'/'+api_token
      ]
    }
  },
}

</script>

<style lang="scss" scoped>
  #detalle-garantia {
    background-color: $white;
    // min-height: 100vh; 
    margin-bottom: 100px;
    
    .content-tabla {
      color: $text-primary;
      padding-right: 0;
      
      .generales-data {
        display: flex;
        align-items: center;
        span{
          color: $text-primary;
          line-height: 1.5;
          &.text-right{
            text-transform: capitalize;
          }
        }
        
        .heanding-mobile {
          padding-left: 0;
        }
        .text-right {
          font-weight: 700;
          padding-right: 0;
        }
      }
    }
    
    @include mq-min(tablet){
      min-height: 49vh; 
    }
    
    .garantia {
      padding: 2rem 0;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
 h2 {
    color: $color-blue;
    padding: 1rem;
  }
</style>